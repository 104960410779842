import Sale from './sale'
import { Link } from "gatsby"

export function DefaultSales() {
  return (
    <ul>
      <li><a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=887448077" rel="nofollow noopener" target="_blank"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=887448077" height="1" width="1" border="0" alt=""/>マウスコンピューター　期間限定セール</a></li>
      <li><a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=296568.10003522&type=3&subid=0" rel="nofollow noopener" target="_blank">Dell 広告掲載・売れ筋モデル</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=296568.10003522&type=3&subid=0" />
      </li>
      <li>
      <a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=83593.10000276&type=3&subid=0" rel="nofollow noopener" target="_blank">パソコン工房のセール：パソコン・PCパーツ・周辺機器がお買い得！</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=83593.10000276&type=3&subid=0" />
      </li>
      <li>
      <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=887448116" rel="nofollow noopener" target="_blank"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=887448116" height="1" width="1" border="0" alt=""/>LENOVO Webストア限定Sale会場</a>
      </li>
      <li>
      <a href="https://click.linksynergy.com/deeplink?id=xsv0FDnJs1M&mid=35909&murl=https%3A%2F%2Fjp.ext.hp.com%2Fcampaign%2Fpersonal%2F" rel="nofollow noopener" target="_blank">HPのキャンペーンコレクション</a><img alt="icon" width="1" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=252926.1&type=10"/>
      </li>
      <li>
      <a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=183092.10000156&type=3&subid=0" target="_blank" rel="nofollow noopener">富士通</a><img border="0" width="1" height="1" alt="" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=183092.10000156&type=3&subid=0" />： 特別クーポン【SPLSJ】で通常クーポンから更に割引。詳細は<Link to="/fujitsu/">こちら</Link>
      </li>
      <li><a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=887448107" rel="nofollow noopener" target="_blank"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=887448107" height="1" width="1" border="0" alt=""/>NEC広告限定クーポン</a></li>
      <li>Dynabook: <a href="https://linksynergy.jrs5.com/fs-bin/click?id=xsv0FDnJs1M&offerid=233988.10001860&type=3&subid=0" rel="nofollow noopener" target="_blank">特設サイト</a><img width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=233988.10001860&type=3&subid=0" />から購入で通常より割引価格で【ID:dyna204cls, PASS:T8Y7GRSV】</li>
      <li>
      <a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=887817.286&type=3&subid=0" rel="nofollow noopener" target="_blank">Microsoft Store： 公式サイト限定の学生割引、お買い得商品</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=887817.286&type=3&subid=0" />
      </li>
      <li>
      <a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=623694.61&type=3&subid=0" rel="nofollow noopener" target="_blank">ASUS 限定キャンペーン実施中！仕事から遊びまで人気PCを多数ご用意！</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=623694.61&type=3&subid=0" />
      </li>
      <li>
      <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=887448087" rel="nofollow noopener" target="_blank"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=887448087" height="1" width="1" border="0" alt=""/>パソコンショップSEVEN　ハイエンドデスクトップBTOパソコンセール開催中! </a>
      </li>
    </ul>
  );
}

export default function SaleDefault(props) {
  return (
    <Sale {...props} >
      <DefaultSales/>
    </Sale>
  );
}